import DataLayerScope from 'swp-components/lib/components/common/data-layer-scope'
import Footer from 'swp-components/lib/components/footer'
import Section from 'swp-components/lib/components/section'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import BottomBanner from '@/components/bottom-banner'
import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Faq from '@/components/faq'
import Header from '@/components/header'
import HighlightBanner from '@/components/highlight-banner'
import MobilePackage from '@/components/mobile-package'
import MobilePackageType from '@/components/mobile-package-type'
import Newrelic from '@/components/newrelic-agent'
import News from '@/components/news'
import SectionHeading from '@/components/section-heading'
import Seo from '@/components/seo'
import ShortcutMenu from '@/components/shortcut-menu'
import TopBanner from '@/components/top-banner'
import useTruemovehPrepaidPageDataLayer from '@/hooks/data-layers/truemoveh/use-truemoveh-prepaid-page'
import useMobilePackage from '@/hooks/use-mobile-package'
import strapi from '@/strapi'
import { useEffect } from 'react'

const TrueMoveHPrepaidPage = ({ serverData }) => {
  let [
    seo,
    headerScript,
    pageHeaders,
    brandHeaders,
    productServices,
    topBanners,
    shortcutMenus,
    prepaidPackages,
    highlightBanners,
    packageTypes,
    extraPackages,
    bottomBanners,
    faq,
    footerHeaders,
    chatShop,
    newsList,
    campaignEvent,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)

  let mobilePrepaidPackages = useMobilePackage('tmh-highlight-prepaid', {
    prepaidPackages,
  })

  let mobileExtraPackages = useMobilePackage('tmh-extra', {
    extraPackages,
    category: 'prepaid',
  })

  let arr = []

  if (newsList.length > 0) {
    newsList.map((a, index) => {
      a.link = {
        url: `${serverData.locale !== 'th' ? '/en' : ''}/news/truemoveh/${
          a.slug
        }`,
        newTab: true,
      }
      a.startDate = a.updatedAt
      if (a.isHighlight === true) {
        arr.push(a)
      }
    })
    newsList.map(a => {
      if (a.isHighlight === false) {
        arr.push(a)
      }
    })
  }

  let { handleMessage, createScopeProps } = useTruemovehPrepaidPageDataLayer()

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={seo.tmhPrepaidSeo} script={headerScript.script} />
        <DataLayerScope {...createScopeProps('Header')}>
          <Header items={brandHeaders} services={productServices} />
        </DataLayerScope>
        {topBanners.length > 0 && (
          <DataLayerScope {...createScopeProps('TopBanner', { topBanners })}>
            <TopBanner banners={topBanners} />
          </DataLayerScope>
        )}
        {shortcutMenus.length > 0 && (
          <Section.Background className="py-4" color="light-orange">
            <DataLayerScope {...createScopeProps('ShortcutMenu')}>
              <ShortcutMenu color="orange" items={shortcutMenus} />
            </DataLayerScope>
          </Section.Background>
        )}
        {pageHeaders.tmhPrepaidPage.packageHeader &&
          mobilePrepaidPackages.length > 0 && (
            <div className="my-5" id="packages">
              <>
                <SectionHeading variant="underline" tag="h1">
                  {pageHeaders.tmhPrepaidPage.packageHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" level="2">
                  {pageHeaders.tmhPrepaidPage.packageHeader.detail}
                </SectionHeading>
              </>

              <DataLayerScope
                {...createScopeProps('MobilePackage', {
                  mobilePrepaidPackages,
                  header: pageHeaders.tmhPrepaidPage.packageHeader,
                })}
              >
                <MobilePackage packages={mobilePrepaidPackages} />
              </DataLayerScope>
            </div>
          )}
        {highlightBanners.length > 0 && (
          <DataLayerScope
            {...createScopeProps('HighlightBanner', { highlightBanners })}
          >
            <HighlightBanner
              className="my-5"
              columns={3}
              banners={highlightBanners}
            />
          </DataLayerScope>
        )}
        {pageHeaders.tmhPrepaidPage.packageTypeHeader &&
          packageTypes.length > 0 && (
            <div className="my-5">
              <>
                <SectionHeading variant="underline" tag="h2">
                  {pageHeaders.tmhPrepaidPage.packageTypeHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" level="2">
                  {pageHeaders.tmhPrepaidPage.packageTypeHeader.detail}
                </SectionHeading>
              </>

              <DataLayerScope
                {...createScopeProps('MobilePackageType', {
                  packageTypes,
                  header: pageHeaders.tmhPrepaidPage.packageTypeHeader,
                })}
              >
                <MobilePackageType type="prepaid" packageTypes={packageTypes} />
              </DataLayerScope>
            </div>
          )}
        {pageHeaders.tmhPrepaidPage.extraPackageHeader &&
          mobileExtraPackages.length > 0 && (
            <div className="my-5">
              <>
                <SectionHeading variant="underline" tag="h2">
                  {pageHeaders.tmhPrepaidPage.extraPackageHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" level="2">
                  {pageHeaders.tmhPrepaidPage.extraPackageHeader.detail}
                </SectionHeading>
              </>

              <DataLayerScope
                {...createScopeProps('MobilePackage', {
                  mobileExtraPackages,
                  header: pageHeaders.tmhPrepaidPage.extraPackageHeader,
                })}
              >
                <MobilePackage packages={mobileExtraPackages} />
              </DataLayerScope>
            </div>
          )}
        {campaignEvent && newsList.length > 0 && (
          <Section.Background className="py-5">
            {pageHeaders.tmhPostpaidPage.campaignEventHeader && (
              <>
                <SectionHeading variant="underline" tag="h2">
                  {pageHeaders.tmhPostpaidPage.campaignEventHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" level="2">
                  {pageHeaders.tmhPostpaidPage.campaignEventHeader.detail}
                </SectionHeading>
              </>
            )}
            <DataLayerScope
              {...createScopeProps('News', {
                ...arr,
                header: pageHeaders.tmhPostpaidPage.campaignEventHeader,
              })}
            >
              <News
                articles={arr}
                viewAll={{ link: { url: '/news/truemoveh', newTab: true } }}
              />
            </DataLayerScope>
          </Section.Background>
        )}
        {bottomBanners.length > 0 && (
          <DataLayerScope {...createScopeProps('BottomBanner')}>
            <BottomBanner banners={bottomBanners} />
          </DataLayerScope>
        )}
        {faq && (
          <Section.Background className="py-5" color="orange" circle>
            {pageHeaders.tmhPrepaidPage.faqHeader && (
              <>
                <SectionHeading color="white" tag="h2">
                  {pageHeaders.tmhPrepaidPage.faqHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" color="white" level="2">
                  {pageHeaders.tmhPrepaidPage.faqHeader.detail}
                </SectionHeading>
              </>
            )}
            <DataLayerScope
              {...createScopeProps('Faq', {
                ...faq,
                header: pageHeaders.tmhPrepaidPage.faqHeader,
              })}
            >
              <Faq color="orange" topics={faq.faqs} viewAll={faq.viewAll} />
            </DataLayerScope>
          </Section.Background>
        )}
        {footerHeaders && (
          <DataLayerScope {...createScopeProps('Footer')}>
            <Footer className="pb-4" items={footerHeaders} />
          </DataLayerScope>
        )}
        {chatShop && (
          <DataLayerScope {...createScopeProps('ChatShop')}>
            <ChatShop
              content={chatShop}
              mariPlatform={ serverData.locale == 'th' ? "mari_tmh" : "truemoveh" }
              mariConfig={mariConfig}
            />
          </DataLayerScope>
        )}
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default TrueMoveHPrepaidPage

export const getServerData = async ({ pageContext }) => {
  let contents = await strapi.getContents(
    [
      'seo',
      'header-script',
      'header-page',
      'brand-headers',
      'product-services',
      'tmh-prepaid-top-banners',
      'tmh-prepaid-shortcut-menus',
      'tmh-prepaid-packages',
      'tmh-prepaid-highlight-banners',
      'tmh-prepaid-package-types',
      'tmh-extra-packages',
      'tmh-prepaid-bottom-banners',
      'tmh-prepaid-faq',
      'footer-headers',
      'chat-and-shop',
      `news-and-events/?page_group.slug=truemoveh&_start=0&_limit=7&_locale=${pageContext.locale}`,
      'tmh-postpaid-campaign-event',
      'mari-chat-and-shop-config',
    ],
    pageContext.locale
  )

  return {
    props: { contents, locale: pageContext.locale },
  }
}
